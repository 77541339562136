<script setup lang="ts">
import logo from '@/assets/img/logo.png';
import homeActive from '@/assets/img/nav/ic-home-active.svg';
import home from '@/assets/img/nav/ic-home.svg';
import logout from '@/assets/img/nav/ic-logout-icon.svg';
import notificationsActive from '@/assets/img/nav/ic-notifications-active.svg';
import notifications from '@/assets/img/nav/ic-notifications.svg';
import profileActive from '@/assets/img/nav/ic-profile-active.svg';
import profile from '@/assets/img/nav/ic-profile.svg';
import requestActive from '@/assets/img/nav/ic-request-active.svg';
import request from '@/assets/img/nav/ic-request.svg';
import searchActive from '@/assets/img/nav/ic-search-active.svg';
import search from '@/assets/img/nav/ic-search.svg';
import { useAuthStore } from '@/modules/auth/store/auth';
import { storeToRefs } from 'pinia';
import { AppFormCard, AppTypography } from './base';
import AppButton from './base/AppButton.vue';
import NavbarMenu from './NavbarMenu.vue';
const { hasLoggedInUser } = storeToRefs(useAuthStore());
import { useFilterUser } from '@/store/filterUser';

const navigations = [
  {
    title: 'Home',
    icon: home,
    activeIcon: homeActive,
    link: { name: 'index' },
  },
  {
    title: 'Notifications',
    icon: notifications,
    activeIcon: notificationsActive,
    link: { name: 'notifications' },
  },
  {
    title: 'Search',
    icon: search,
    activeIcon: searchActive,
    link: { name: 'search' },
  },
  {
    title: 'Requests',
    icon: request,
    activeIcon: requestActive,
    link: { name: 'requests' },
  },
  {
    title: 'Profile',
    icon: profile,
    activeIcon: profileActive,
    link: { name: 'profile' },
  },
];
const route = useRoute();
const { signOut } = useAuth();

const { resetFilterOptions } = useFilterUser();
const { resetOptions } = useAuthStore();
const handleLogout = async () => {
  resetFilterOptions();
  resetOptions();
  await signOut({ redirect: false });
  window.location.href = '/login';
};

const isActive = (nav): boolean => {
  return route.name.split('-')[0] === nav.link.name;
};

const toggleMobileMenu = ref(false);

const closeMenu = () => (toggleMobileMenu.value = false);

watch(
  () => toggleMobileMenu.value,
  (value) => {
    const html = document.querySelector('html');
    if (html) {
      html.style.overflow = value ? 'hidden' : 'auto';
    }
  }
);
</script>

<template>
  <v-container fluid max-width="232">
    <AppFormCard noGutter :class="{ 'toggle-menu': toggleMobileMenu }">
      <div class="navbar" :class="{ 'navbar--active': toggleMobileMenu }">
        <div class="d-flex ga-1 align-center flex-grow-1">
          <img :src="logo" width="28" height="28" />
          <AppTypography class="app-name">SupportApp</AppTypography>
        </div>

        <AppButton
          @click="toggleMobileMenu = !toggleMobileMenu"
          variant="text"
          class="mobile-menu-btn"
          :icon="toggleMobileMenu ? 'mdi-close' : 'mdi-menu'"
        />
      </div>

      <NavbarMenu @navigate="closeMenu" v-if="toggleMobileMenu">
        <template #default="{ isComingSoon }">
          <v-chip size="small" color="primary" v-if="isComingSoon">
            <AppTypography type="caption-1">Upcoming</AppTypography>
          </v-chip>
        </template>
        <template #prepend="{ nav }">
          <img :src="isActive(nav) ? nav?.activeIcon : nav?.icon" width="24" height="24" />
        </template>
      </NavbarMenu>

      <v-list class="mt-auto pa-4 logout-btn" v-if="toggleMobileMenu">
        <v-list-item @click="handleLogout" class="ga-4 d-flex align-center">
          <AppTypography type="body-2" v-if="hasLoggedInUser"> Logout </AppTypography>
          <template v-slot:prepend>
            <img :src="logout" width="24" height="24" />
          </template>
        </v-list-item>
      </v-list>
    </AppFormCard>
  </v-container>
</template>

<style lang="scss" scoped>
.v-list {
  padding: 0 !important;
}

.logout-btn {
  padding-bottom: env(safe-area-inset-bottom) !important;
}

:deep(.v-list-item) {
  color: rgb(var(--v-theme-tertiary)) !important;
  border-radius: 0px !important;

  &.v-list-item--disabled {
    opacity: 1;
  }

  .v-chip {
    border-radius: 10px !important;
    padding: 0px 10px !important;
    text-transform: uppercase;

    &.v-chip--size-small {
      min-height: 20px !important;
      height: 20px !important;
      font-weight: 700 !important;
    }
  }

  &:hover {
    background-color: rgb(var(--v-theme-primary-3)) !important;
  }

  .v-list-item__content {
    display: flex;
    gap: 8px;
  }

  &.v-list-item--active {
    background-color: rgb(var(--v-theme-primary-3)) !important;
    color: rgb(var(--v-theme-primary)) !important;

    .v-list-item__overlay {
      opacity: 0 !important;
    }

    p {
      color: rgb(var(--v-theme-primary)) !important;
      font-weight: 700 !important;
      line-height: 24px !important;
      text-align: left;
    }
  }
}

.navbar--active {
  border-bottom: 1px solid #e6e6e7;
}

.mobile-menu-btn {
  min-height: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  border-radius: 4px !important;
  padding: 0;
}

.v-card {
  height: 59px;
  border-radius: 0;
  padding: 0 !important;

  :deep(.v-card-text) {
    height: 100dvh;
    display: flex;
    flex-direction: column;
  }

  &.toggle-menu {
    height: 100dvh;
  }
}

.navbar {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px !important;
}

.v-container {
  max-width: 100vw !important;
  z-index: +1;
  position: fixed;
  padding: 0;
  display: unset !important;

  @media (min-width: 768px) {
    display: none !important;
  }
}

.app-name {
  font-family: 'Excon';
  font-size: 19px !important;
  font-weight: 700 !important;
  line-height: 26.98px !important;
  text-align: center !important;
  color: rgb(var(--v-theme-primary));
}
</style>
