<script setup lang="ts">
import { AppTypography } from '@/components';
import { comingSoonPages } from '@/enums/ComingSoon';
import { adminConfirmedPages } from '@/enums/AdminConfirmedPages';
import homeActive from '@/assets/img/nav/ic-home-active.svg';
import home from '@/assets/img/nav/ic-home.svg';
import notificationsActive from '@/assets/img/nav/ic-notifications-active.svg';
import notifications from '@/assets/img/nav/ic-notifications.svg';
import profileActive from '@/assets/img/nav/ic-profile-active.svg';
import profile from '@/assets/img/nav/ic-profile.svg';
import requestActive from '@/assets/img/nav/ic-request-active.svg';
import request from '@/assets/img/nav/ic-request.svg';
import searchActive from '@/assets/img/nav/ic-search-active.svg';
import search from '@/assets/img/nav/ic-search.svg';
import { useAuthStore } from '@/modules/auth/store/auth';
import { ComplateProfileDialog } from '@/core/components';

const { user } = useAuthStore();

const emits = defineEmits<{
  (e: 'navigate'): void;
}>();

const navigations = [
  {
    title: 'Home',
    icon: home,
    activeIcon: homeActive,
    name: 'index',
    link: '/home',
  },
  {
    title: 'Notifications',
    icon: notifications,
    activeIcon: notificationsActive,
    link: { name: 'notifications' },
  },
  {
    title: 'Search',
    icon: search,
    activeIcon: searchActive,
    link: { name: 'search' },
  },
  {
    title: 'Requests',
    icon: request,
    activeIcon: requestActive,
    link: { name: 'requests' },
  },
  {
    title: 'Profile',
    icon: profile,
    activeIcon: profileActive,
    name: 'profile',
    link: '/my-profile',
  },
];

const route = useRoute();

const isActive = (nav): boolean => {
  return route.name.split('-')[0] === nav.link.name || route.name.split('-')[0] === nav?.name;
};

const dialog = ref(false);
const onClick = async (nav: any): Promise<void> => {
  if (adminConfirmedPages.includes(nav?.name || nav.link?.name) && !user.profile_documents_verified) {
    dialog.value = true;
  } else {
    emits('navigate');
    await navigateTo(nav.link);
  }
};
</script>

<template>
  <v-list class="pa-4 pt-7" color="transparent">
    <v-list-item
      v-for="(nav, index) in navigations"
      :key="index"
      class="ga-4 d-flex align-center"
      @click="onClick(nav)"
      :active="isActive(nav)"
      :disabled="comingSoonPages.includes(nav.title.toLowerCase())"
    >
      <AppTypography type="body-2">
        {{ nav.title }}
      </AppTypography>
      <slot :isComingSoon="comingSoonPages.includes(nav.title.toLowerCase())"></slot>

      <template #prepend>
        <slot name="prepend" :nav="nav" :isActive="isActive"> </slot>
      </template>
    </v-list-item>
  </v-list>
  <ComplateProfileDialog v-model="dialog" @close="dialog = false" />
</template>
