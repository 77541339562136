<script setup lang="ts">
import logo from '@/assets/img/logo.png';
import { useFilterUser } from '@/store/filterUser';
import logout from '@/assets/img/nav/ic-logout-icon.svg';
import { useAuthStore } from '@/modules/auth/store/auth';
import { storeToRefs } from 'pinia';
import { AppFormCard, AppTypography } from './base';
import NavbarMenu from './NavbarMenu.vue';

const { resetFilterOptions } = useFilterUser();
const { resetOptions } = useAuthStore();
const { hasLoggedInUser } = storeToRefs(useAuthStore());

const { signOut } = useAuth();

const handleLogout = async () => {
  await resetFilterOptions();
  await resetOptions();
  await signOut({ redirect: false });
  window.location.href = '/login';
};
</script>

<template>
  <v-container max-width="232">
    <AppFormCard>
      <div class="d-flex ga-1 justify-center align-center">
        <img :src="logo" width="28" height="28" />
        <AppTypography class="app-name">SupportApp</AppTypography>
      </div>

      <NavbarMenu>
        <template #default="{ isComingSoon }">
          <v-chip size="small" color="primary" v-if="isComingSoon">
            <AppTypography type="caption-1">Upcoming</AppTypography>
          </v-chip>
        </template>
        <template #prepend="{ nav, isActive }">
          <img :src="isActive(nav) ? nav?.activeIcon : nav?.icon" width="24" height="24" />
        </template>
      </NavbarMenu>

      <v-list class="mt-auto pa-4">
        <v-list-item @click="handleLogout" class="ga-4 d-flex align-center">
          <AppTypography type="body-2" v-if="hasLoggedInUser"> Logout </AppTypography>
          <template v-slot:prepend>
            <img :src="logout" width="24" height="24" />
          </template>
        </v-list-item>
      </v-list>
    </AppFormCard>
  </v-container>
</template>

<style lang="scss" scoped>
:deep(.v-list-item) {
  color: rgb(var(--v-theme-tertiary)) !important;
  border-radius: 8px !important;

  &.v-list-item--disabled {
    opacity: 1;
  }

  .v-chip {
    border-radius: 10px !important;
    padding: 0px 10px !important;
    text-transform: uppercase;

    &.v-chip--size-small {
      min-height: 20px !important;
      height: 20px !important;
      font-weight: 700 !important;
    }
  }

  &:hover {
    background-color: rgb(var(--v-theme-primary-3)) !important;
  }

  &.v-list-item--active {
    background-color: rgb(var(--v-theme-primary-3)) !important;
    color: rgb(var(--v-theme-primary)) !important;
    border-radius: 8px !important;

    .v-list-item__overlay {
      opacity: 0 !important;
    }

    p {
      color: rgb(var(--v-theme-primary)) !important;
      font-weight: 700 !important;
      line-height: 24px !important;
      text-align: left;
    }
  }
}

.v-card {
  padding: 0 !important;
  height: calc(100vh - 32px);

  :deep(.v-card-text) {
    height: calc(100vh - 52px);
    display: flex;
    flex-direction: column;
  }
}

.v-tooltip__content {
  background-color: rgb(var(--v-theme-primary)) !important;
}

.v-container {
  max-width: 248px;
  position: fixed;
  z-index: +1;
  display: none;

  @media (min-width: 768px) {
    display: unset;
  }
}

.app-name {
  font-family: 'Excon';
  font-size: 19px !important;
  font-weight: 700 !important;
  line-height: 26.98px !important;
  text-align: center !important;
  color: rgb(var(--v-theme-primary));
}
</style>
