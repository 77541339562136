<script lang="ts" setup>
import { AppButton } from '@/core/components/base';
import { useAuthStore } from '@/modules/auth/store/auth';

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const { isWorker } = useAuthStore();
const dialog = defineModel();
</script>

<template>
  <v-dialog v-model="dialog" max-width="375">
    <template v-slot:default="{ isActive }">
      <v-card class="card">
        <v-card-title class="text-h5">
          <div class="d-flex">
            <div class="mx-auto font-weight-bold mt-2">Complete Your Profile</div>
            <v-btn class="ma-0" icon="mdi-close" flat variant="icon" size="small" @click="isActive.value = false" />
          </div>
        </v-card-title>
        <v-card-text>
          <p>
            Complete your profile to find and send a request to
            {{ isWorker ? 'Support Seekers.' : 'Support Workers.' }}
          </p>
        </v-card-text>

        <v-card-actions class="ma-2">
          <AppButton to="/my-profile" block @click="emits('close')"> Complete Account Setup</AppButton>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-card.card {
  background-color: #faf8f6;
}

.v-card-item .v-card-title {
  text-align: center !important;
}
</style>
